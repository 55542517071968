


.ribbon-calendar__today {
  background-color: #FEEBF7 !important;
}

.ribbon-event__title {
  color: $anchors !important;
}

//#momence-plugin-host-schedule {
//  button.momence-host_schedule-session_list-item-book_button {
//    font-weight: 600 !important;
//  }
//}

//sc-jgb7hk-0 ghpWrh momence-button sc-1b0cndk-6 jixwtx momence-host_schedule-session_list-item-book_button