
header {
  //padding-bottom: rem-calc(30px);
  background: white url("/assets/img/childs_pose_wide_5177_2034.jpg") center center/100% no-repeat;

  .cell--promobanner {
    background-color: $banner;
    color: $white;
    font-size: rem-calc(18px);
    padding-top: rem-calc(10px);
    padding-bottom: rem-calc(10px);
  }


  .cell--logo {
    //padding: rem-calc(10px 0);
    .logo {
      img {
        padding: rem-calc(40px 30px 40px);
        //max-width: map-get($breakpoints, "medium");
        width: 100%;
      }

      h1 {
        padding-top: rem-calc(30px);
        margin: 0;
        color: #ffffff;
        font-family: "Lato", Roboto, Arial, sans-serif;
        font-weight: 900;
        font-style: normal;
        //font-family: 'Bungee Shade', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
        font-size: rem-calc(80px);
      }
    }
  }

  .cell--slogan {
    background-color: $borders;
    .h2 {
      text-transform: uppercase;
      margin: rem-calc(10px 0);
      color: $white;
      font-family: "Lato", Roboto, Arial, sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: rem-calc(16px);
      letter-spacing: 0.1rem;
    }
  }

  .cell--menu {
    background-color: rgba(255, 255, 255, 0.7);
    padding-top: rem-calc(10px);
    padding-bottom: rem-calc(10px);
    //margin-bottom: rem-calc(5px);
    .menu {
      a {
        font-size: rem-calc(26px);
        font-weight: 400;
        letter-spacing: 0.15rem;
        text-transform: uppercase;
        color: #363636;
      }
    }
  }
}

.off-canvas.position-left {
  padding-top: rem-calc(25px);
  .menu {
    li.separator-menu {
      padding-bottom: rem-calc(10);
      border-bottom: 1px solid $white;
      margin-bottom: rem-calc(10);
    }
    a {
      color: $white;
    }
  }
}



/* HOMEPAGE */

.homepage-intro {
  padding-bottom: rem-calc(30px);
}

.cell--offers {
  margin-top: rem-calc(30px);
}

//.homepage-banner {
//  margin-top: rem-calc(30px);
//
//  .fa-star {
//    color: $borders; //#FFC11F;
//  }
//
//  margin-bottom: rem-calc(30px);
//}

.fa-heart {
  color: $headers; // color: red;
}

/* Classes page */

.pricing {
  margin-top: rem-calc(30px);
}

.pricing-structure {

  th {
    font-size: rem-calc(20);
  }

  h6 {
    //margin: 0;
  }

  a {
    display: block;
    margin-bottom: 0.5rem;
  }
}

/** All Pages */

main {
  margin-top: rem-calc(40px);
  margin-bottom: rem-calc(40px);
}

.cell--title {
  padding-top: rem-calc(20px);
  padding-bottom: rem-calc(20px);
}


.card-header {
  padding: 0.2rem 1rem;
  background-color: $borders;

  .h3,
  .h4,
  .h5,
  .h6,
  h3,
  h4,
  h5,
  h6 {
    color: $white;
  }
}

/* Footer */
footer {

  margin-top: rem-calc(50px);
  //padding-top: rem-calc(20px);
  //padding-bottom: rem-calc(20px);

  color: $white;

  .footer-menu {
    padding-bottom: rem-calc(30px);

    a {
      //color: $buttons;
      //font-size: rem-calc(20px);
      //font-weight: 700;
      font-size: rem-calc(18px);
      font-weight: 400;
      letter-spacing: 0.05rem;
      text-transform: uppercase;
      //color: #363636;
    }

  }

  .grid--social-locations,
  .cell--social,
  .cell--locations {
    background-color: $borders;
  }

  .cell--social {
    padding-top: rem-calc(20px);
    p {
      margin: 0;
    }
    .social-menu {
      padding-bottom: rem-calc(20px);
      a {
        color: #ffffff; //$buttons;
        font-size: rem-calc(30px);
        font-weight: 700;
      }
    }
  }

  .locations,
  .copyright {
    color: $headers;
  }
}

.small-print {
  font-size: rem-calc(14px);
}
